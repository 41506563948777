export function AutoHeightSync(Splide) {

  function mount() {
    Splide.on('mounted resize', onResize);
  }
  function getHeight() {
    if (window.innerWidth < 768 || window.innerWidth >= 1024 && window.innerWidth < 1440) {
      return 'auto';
    }
    return `${$('.j_product-slider').height()}px`;
  }

  function onResize() {
    Splide.options = {
      height: getHeight(),
    };
  }

  return {
    mount,
  };
}


export function FilterMobileSlides(Splide) {
  let isDesktop = false;
  let mobileSlidersOnly = null;

  function mount() {
    const el = $('[data-config-name="hero-slider"]');

    if ($(el).find('.j_mobile-slider-only').length > 0) {
      mobileSlidersOnly = $(el).find('.j_mobile-slider-only').toArray();
      Splide.on('mounted resize', onResize);
    }
  }

  function onResize() {
    if (window.innerWidth >= 1024 && !isDesktop) {
      isDesktop = true;
      Splide.Components.Slides.remove('.j_mobile-slider-only');
    } else if (window.innerWidth < 1024 && isDesktop) {
      isDesktop = false;
      Splide.Components.Slides.add(mobileSlidersOnly);
    }
  }

  return {
    mount,
  };
}
