import './styles/fonts.css';
import '@splidejs/splide/css/core'
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import 'nouislider/dist/nouislider.css';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'air-datepicker/air-datepicker.css';
import './styles/base.css';

// Alpine.js
import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'

Alpine.plugin(collapse)
Alpine.plugin(focus)
window.Alpine = Alpine
Alpine.start()

window.htmx = require('htmx.org');
import '/node_modules/hystmodal/dist/hystmodal.min.js'
import './scripts/base.js';









function importAll(r) {
    r.keys().forEach(r);
  }

importAll(require.context('./icons', false, /\.svg$/));
